
// 
@import "./custom.scss";

@import "./bootstrap_custom.scss";

@import "~bootstrap/scss/bootstrap.scss";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";