body, #root {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.has-cursor-pointer {
  cursor: pointer;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.object-fit {
  &_contain {
    object-fit: contain;
  }
}